@font-face {
    font-family: "Lato";
    src: url("../fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Italic";
    src: url("../fonts/Lato-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Black";
    src: url("../fonts/Lato-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Bold";
    src: url("../fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Ligth";
    src: url("../fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Thin";
    src: url("../fonts/Lato-Thin.ttf") format("truetype");
}



body {
    background-color: white;
    font-family: Lato;
    overflow: auto !important;
    padding-right: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a {
    line-height: 1;
    word-break: break-word;
    word-wrap: break-word;
    font-weight: 200;
}

a {
    text-decoration: none;
    cursor: pointer;
}

h4 {
    text-transform: uppercase;
    font-family: Lato-Bold !important;
    font-weight: 900 !important;
    font-size: 1.2rem;
    color: #1e1e1e;
    letter-spacing: 2px;
}

.mobile {
    color: black !important;
}

/* FONTS */
.font-1 {
    font-size: 3rem;
}

.font-2 {
    font-size: 2.4rem;
}

.font-3 {
    font-size: 1.8rem;
}

.font-4 {
    font-size: 1.2rem;
}

.font-45 {
    font-size: 0.7rem;
}

.font-5 {
    font-size: 0.6rem;
}

/* BACKGROUNDS */
.bg-ligth-gray {
    background-color: #eeeeee;
}

.bg-dark-gray {
    background-color: #1e1e1e;
}

.bg-dark-footer {
    background-color: #f9fafa;
}

.bg-dark-text-box {
    background-color: #3cdbcd;
}

.bg-text {
    background-color: #485262;
}

/* COLORS */
.cl-ligth-gray {
    color: #eeeeee;
}

.cl-dark-gray {
    color: #1e1e1e;
}

.cl-dark-footer {
    color: #f9fafa;
}

.cl-dark-text-box {
    color: #00ab6c;
}

.cl-text {
    color: #485262;
}

/* PADDING */
.pd-1 {
    padding: 3rem;
}

.pd-2 {
    padding: 2rem;
}

.pd-3 {
    padding: 1rem;
}

.pd-4 {
    padding: .8rem;
}

/* TEXT */
.txt-bold {
    font-family: Lato-Bold;
}

/* BTN */
.btn-close {
    background-color: transparent !important;
}

/* thumbnail video */
.video-element-list {
    width: 50px;
    height: auto;
}

.video-element-list.no-controls::-webkit-media-controls {
    display: none !important;
}

/* VIEW MORE */
.view-more {
    background-color: #1e1e1e;
    margin-bottom: 0rem;
    color: white;
    font-family: Lato-Bold;
    text-transform: uppercase;
    text-align: center;
    height: 3.4rem;
}

.view-more .more-container {
    padding-top: 0.2rem;
}

.view-more a {
    padding-top: 0px;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 0.8rem;
    color: white;
}

@media (max-width: 991px) {
    .view-more {
        height: 3.5rem;
    }

    .view-more a {
        color: white;
    }

    .view-more i {
        font-size: 1rem;
    }
}

@media (max-width: 767px) {
    .view-more {
        height: 3.3rem;
    }

    .view-more .more-container {
        padding-top: .1rem;
    }

    .view-more a {
        color: white;
        font-size: .7rem;
    }

    .view-more i {
        font-size: 1rem;
    }
}

/* SIGN IN */
.form-signin {
    max-width: 80vh;
    padding: 5rem;
    font-family: Lato;
}

.form-signin h1 {
    text-transform: uppercase;
}

.form-signin label {
    text-transform: uppercase;
    font-size: .8rem;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.form-signin button {
    background-color: #3cdbcd !important;
    color: white !important;
    text-transform: uppercase !important;
}

.form-signin .link {
    padding-top: 10rem;
}

.form-signin .copyrigth {
    font-size: .7rem;
}

.form-signin .btn-close {
    background-color: transparent !important;
}

@media (max-width: 576px) {
    .form-signin {
        max-width: 80vh;
        padding: 1rem;
        font-family: Lato;
    }
}

/* MENU */
.menu .navbar-brand img {
    width: 2.5rem;
}

.menu a {
    text-transform: capitalize;
    font-family: Lato;
    color: #1e1e1e;
}

.menu .navbar-toggler {
    border: 0px;
    border-radius: 0px;
}

.menu .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}

/* CONTENT CMS CONTAINER */
/* Table */
table thead th {
    text-transform: uppercase;
    font-family: Lato-Bold !important;
    font-weight: 900 !important;
    font-size: .8rem;
    color: #1e1e1e;
    letter-spacing: 2px;
}

/* btn */
.content-cms .btn {
    text-transform: uppercase;
    font-family: Lato-Bold !important;
    font-weight: 900 !important;
    font-size: .8rem;
    letter-spacing: 1px;
}

.btn-save {
    background-color: #3cdbcd !important;
    color: white !important;
}

.content-cms .form-floating>.form-select {
    text-transform: capitalize;
}

.icon-check {
    font-size: 8rem;
    padding: 2rem;
}

.content-cms .badge {
    text-transform: uppercase;
}

.content-cms table tbody tr .text-table {
    text-transform: capitalize;
}

.content-cms table tbody tr th img {
    width: 4rem;
}

.content-cms .gallery-images h1 {
    text-transform: uppercase;
    font-size: 2rem;
}